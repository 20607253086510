import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Bio from '../components/bio';
import LightHeader from '../components/header/light-header';
import Newsletter from '../components/newsletter';
import PageWrapper from '../components/page/page-wrapper';
import SEO from '../components/seo';
import { media } from '../utils/responsive';
import { rhythm, scale } from '../utils/typography';

const PostTitle = styled.h1`
  font-weight: 900;
  font-size: 32px;
  margin-top: 0;

  ${media.medium`
    font-size: 48px;
  `}

  ${media.large`
    font-size: 56px;
  `}
`;

const PostDate = styled.p({
  ...scale(-1 / 5),
  display: 'block',
  marginBottom: rhythm(1),
  marginTop: rhythm(-1),
});

const PostContent = styled.div`
  p:has(.gatsby-resp-image-wrapper[style*='max-width: 1044px;']) {
    margin: 0 -20px;

    ${media.medium`
      margin: 0 -30px;
    `}

    @media screen and (min-width: 756px) {
      margin: 0 calc((100vw - (var(--page-container-width) - 60px)) / -2);
    }

    @media screen and (min-width: 1044px) {
      margin: 0 -174px;
    }
  }

  ul,
  ol {
    margin-left: 1.75rem;
  }

  a.footnote-ref::before {
    content: '[';
  }

  a.footnote-ref::after {
    content: ']';
  }

  .footnotes li p {
    display: inline;
  }

  .footnotes ol li::marker {
    content: '[' counter(list-item) '] ';
  }

  .gatsby-resp-image-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .grvsc-container {
    --grvsc-padding-h: 20px;

    margin: 0 -20px;
    border-radius: 0;

    ${media.medium`
      --grvsc-padding-h: 30px;

      margin: 0 -30px;
    `}

    @media screen and (min-width: 758px) {
      border-radius: 8px;
    }
  }

  .grvsc-code {
    display: flex;
    flex-direction: column;
  }

  .grvsc-line {
    display: block;
    width: fit-content;
    overflow: hidden;
  }

  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    width: calc(
      100% - var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem))
    );
  }

  .mt-0 {
    margin-top: 0;
  }

  .m-0 {
    margin: 0;
  }

  .mermaid {
    margin-bottom: 1.45rem;

    > svg {
      height: auto !important;
      display: block;
    }
  }
`;

const PostDivider = styled.hr`
  margin-bottom: ${rhythm(1)};
`;

const PrevNextPostsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-top: ${rhythm(2.5)};
`;

export default function BlogPostTemplate(props) {
  const location = useLocation();
  const post = props.data.markdownRemark;
  const { author, siteUrl } = props.data.site.siteMetadata;
  const { previous, next } = props.pageContext;
  const url = typeof window !== 'undefined' ? location.origin : siteUrl;

  return (
    <PageWrapper blog={true}>
      <SEO
        title={post.frontmatter.title}
        keywords={post.frontmatter.keywords}
        description={post.frontmatter.description + '…'}
        image={post.frontmatter.thumbnail.childImageSharp.fluid.src}
        jsonLd={{
          '@type': 'BlogPosting',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': url + '/blog',
          },
          headline: post.frontmatter.title,
          author: {
            '@type': 'Person',
            name: author,
            url: 'https://mertindervish.com',
          },
          datePublished: post.date,
          image: url + post.frontmatter.thumbnail.childImageSharp.fluid.src,
          potentialAction: {
            '@type': 'SubscribeAction',
            object: {
              '@type': 'Product',
              name: `Mertin Dervish's Newsletter`,
              description: 'Subscribe to get my latest content by email.',
              aggregateRating: {
                '@type': 'AggregateRating',
                bestRating: '5',
                ratingValue: '5',
                reviewCount: '1',
              },
            },
          },
        }}
      />
      <LightHeader />
      <PostTitle>{post.frontmatter.title}</PostTitle>
      <PostDate>{post.frontmatter.date}</PostDate>
      <PostContent dangerouslySetInnerHTML={{ __html: post.html }} />
      <PostDivider />
      <Newsletter />
      <PostDivider />
      <Bio />

      {(previous || next) && (
        <PrevNextPostsList>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </PrevNextPostsList>
      )}
    </PageWrapper>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        keywords
        description
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author
        siteUrl
      }
    }
  }
`;
