import React, { useCallback, useRef } from 'react';
import { Element } from 'react-scroll';
import { useFormik } from 'formik';
import styled from 'styled-components';

import Alert from '../components/alert';
import Button from '../components/form/button';
import GridForm from '../components/form/grid-form';
import Input from '../components/form/input';
import { FullCell, HalfCell } from '../components/grid/grid-cell';
import { formikValidation, scroll, typography } from '../utils';
import { media } from '../utils/responsive';
import Grid from './grid/grid';

const NewsletterGridForm = styled(GridForm)`
  font-family: ${typography.sansSerif};
`;

const NewsletterTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 32px;

  ${media.medium`
    font-size: 46px;
  `}
`;

const NewsletterText = styled.p`
  margin-bottom: 0;

  ${media.medium`
    font-size: 22px;
  `}
`;

const SpamText = styled.p`
  margin-top: 15px;
  font-size: 12px;

  ${media.medium`
    font-size: 14px;
  `}
`;

const SubscribeButton = styled(Button)`
  margin-top: 4px;
`;

export default function Newsletter() {
  const formEl = useRef(null);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: { fields: { first_name: '' }, email_address: '' },
    validate: function handleValidation(values) {
      const rules = {
        'fields[first_name]': 'required',
        email_address: 'required|email',
      };

      return formikValidation(values, rules);
    },
    onSubmit: useCallback(() => formEl?.current?.submit(), [formEl]),
  });

  const handleCustomSubmit = useCallback(
    (event) => {
      scroll.scrollTo('newsletter-form');
      formik.handleSubmit(event);
    },
    [formik]
  );

  const hasValidationError = !formik.isValid && formik.submitCount > 0;

  return (
    <NewsletterGridForm
      onSubmit={handleCustomSubmit}
      noValidate
      disabled={formik.isSubmitting}
      name="contact"
      method="POST"
      action="https://app.convertkit.com/forms/1423633/subscriptions"
      ref={formEl}
    >
      <HalfCell>
        <NewsletterTitle>Subscribe to the Newsletter</NewsletterTitle>
        <NewsletterText>
          Subscribe to get my latest content by email.
        </NewsletterText>
      </HalfCell>

      <HalfCell>
        <Element name="newsletter-form">
          <Grid>
            <Alert visible={hasValidationError && true} type="error">
              {Boolean(hasValidationError) &&
                'Please fix the errors and submit again.'}
            </Alert>
            <FullCell>
              <Input
                type="text"
                name="fields[first_name]"
                label="First name"
                form={formik}
                onChange={formik.handleChange}
                value={formik.values.fields.first_name}
              />
            </FullCell>

            <FullCell>
              <Input
                type="email"
                name="email_address"
                label="Email"
                form={formik}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FullCell>

            <FullCell>
              <SubscribeButton type="submit" backgroundColor="primary">
                Subscribe
              </SubscribeButton>
            </FullCell>
          </Grid>

          <SpamText>
            I won&apos;t send you spam. Unsubscribe at any time.
          </SpamText>
        </Element>
      </HalfCell>
    </NewsletterGridForm>
  );
}
