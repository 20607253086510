import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { rhythm } from '../utils/typography';

const BioContainer = styled.div`
  display: flex;
`;

const ProfilePicture = styled(Image)`
  margin-right: ${rhythm(1 / 2)};
  margin-bottom: 0;
  min-width: 50px;
  border-radius: 100%;
`;

const BioText = styled.p`
  line-height: 25px;
  margin: 0;
`;

function Bio() {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
          description
        }
      }
    }
  `);
  const { author, social, description } = data.site.siteMetadata;

  return (
    <BioContainer>
      <ProfilePicture fixed={data.avatar.childImageSharp.fixed} alt={author} />
      <BioText>
        Blog by{' '}
        <a
          href={`https://twitter.com/${social.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {author}
        </a>
        . <br /> {description}
      </BioText>
    </BioContainer>
  );
}

export default Bio;
